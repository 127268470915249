export default {
  name: 'CallMobileProjectCard',
  props: {
    listEvents: {
      type: Array,
      default: () => []
    }
  },
  render (h) {
    return (
      <div class="task__wrapper">
        <div class="task__group">
          {
            this.listEvents.map(item => {
              return (
                <div class="task__item" onClick={() => this.$emit('on-event-click', item)}>
                  <div class="task__title no-margin">
                    {item.name}
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
};
