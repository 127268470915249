<template>
  <pull-refresh
    v-model="dataIsLoading"
    @refresh="() => $_ajax({reset: true})">
    <list
      v-model="dataLoadingList"
      :finished="dataLoadingListFinished"
      finished-text=""
      @load="onLoadList">
      <call-mobile-project-card
        :list-events="dataProjectList"
        @on-event-click="onClickProject" />
    </list>
  </pull-refresh>
</template>

<script>
import { PROJECTS_GET_ALL } from '../../../store/types/actions.types';
import CallMobileProjectCard from '../../../components/mobile/cards/CallMobileProjectCard';
import { mapGetters } from 'vuex';
import { debounce } from 'lodash/function';
import helper from '../../../service/helper';
import { PullRefresh, Toast, List } from 'vant';
import { SET_ALL } from '../../../store/types/mutations.types';

export default {
  name: 'PageProjectsMobile',
  components: { CallMobileProjectCard, PullRefresh, List },
  data () {
    return {
      dataObjPagination: {},
      dataProjectList: [],
      dataIsLoading: false,
      dataLoadingList: false,
      dataLoadingListFinished: false,
      dataPageItemList: -1
    };
  },
  computed: {
    ...mapGetters({
      computedStoreSearch: 'mobile/search',
      computedStoreCurrentUser: 'auth/currentUser'
    })
  },
  watch: {
    computedStoreSearch: debounce(function () {
      if (this.computedStoreSearch === null || this.$router?.currentRoute?.name !== 'Projects') {
        return;
      }
      this.$_ajax({ reset: true });
    }, 1000)
  },
  methods: {
    async $_ajax ({ limit = 10, page = this.dataPageItemList, loadList = false, reset = false } = {}) {
      if (reset) {
        this.dataPageItemList = 0;
        page = 0;
        this.dataLoadingListFinished = false;
      }

      const { payload: { list = [] }, misc: { paging = {} } } = await this.$store.dispatch(`project/${PROJECTS_GET_ALL}`, {
        params: {
          search: this.computedStoreSearch,
          team_ids: [helper.getOneArray(this.computedStoreCurrentUser)?.id],
          limit,
          page
        }
      });
      this.dataObjPagination = paging;
      if (loadList) {
        this.dataProjectList = [...this.dataProjectList, ...list];
      } else {
        this.dataProjectList = list;
      }
      if (list.length === 0) {
        this.dataLoadingListFinished = true;
      }

      if (this.dataIsLoading) {
        Toast('Refresh Success');
        this.dataIsLoading = false;
      }
    },
    onClickProject ({ id: userId, name = '' }) {
      this.$store.commit(`mobile/${SET_ALL}`, {
        data: null,
        key: 'search'
      });
      this.$router.push({
        name: 'ProjectTasks',
        params: {
          projectId: userId
        },
        query: {
          projectName: name,
          reset: true
        }
      });
    },
    async onLoadList () {
      this.dataPageItemList += 1;

      await this.$_ajax({ loadList: true });

      this.dataLoadingList = false;

      const { count = 0 } = this.dataObjPagination;

      if (this.dataProjectList.length >= count) {
        this.dataLoadingListFinished = true;
      }
    }
  }
};
</script>
